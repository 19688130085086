<template lang="pug">
.content
    Skeleton(v-if="isLoading")

    template(v-else)
        .mb-16.flex.items-center
            ul.flex.items-center
                li.mr-28(v-for="tab in tabs" :key="tab")
                    TabButton(:active="activeTab === tab" @click="changeTab(tab)")
                        | {{ tab }}

            ul.ml-auto.flex.items-center
                li.mr-12
                    MultiSelectWithSearch(
                        name="Конкуренты"
                        :items="competitors"
                        searchPlaceholder="Поиск конкурентов"
                        hasSelectAll
                        @select-item="selectCompetitor"
                        @select-all="selectAllCompetitors(true)"
                        @clear-all="selectAllCompetitors(false)"
                        @hide="changeInnerFilters"
                    )
                li
                    MultiSelectWithSearch(
                        name="Селлеры"
                        :items="sellers"
                        searchPlaceholder="Поиск селлеров"
                        tooltip-placement="bottom-end"
                        hasSelectAll
                        @select-item="selectSeller"
                        @select-all="selectAllSellers(true)"
                        @clear-all="selectAllSellers(false)"
                        @hide="changeInnerFilters"
                    )
        
        BrandGeneralInfoTable(
            v-if="activeTab === tabs[0]"
            :request-params="tableRequestParams"
            :need-update="needUpdateTable"
        )
        BrandShareOfPromoTable(
            v-else
            :request-params="tableRequestParams"
            :need-update="needUpdateTable"
        )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Skeleton from '@/components/Nestle/Skeleton/Common.vue';
import TabButton from '@/components/BrandsAndSKU/TabButton.vue';
import MultiSelectWithSearch from '@/components/Elements/MultiSelect/MultiSelectWithSearch.vue';
import BrandGeneralInfoTable from '@/components/BrandsAndSKU/BrandGeneralInfoTable.vue';
import BrandShareOfPromoTable from '@/components/BrandsAndSKU/BrandShareOfPromoTable.vue';

const PAGE_TABS = ['Общая информация', 'Доля промо в динамике'];

export default {
    components: {
        Skeleton,
        TabButton,
        MultiSelectWithSearch,
        BrandGeneralInfoTable,
        BrandShareOfPromoTable,
    },

    props: {
    requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            required: true,
        },
  },

    data() {
        return {
            isLoading: false,
            tabs: PAGE_TABS,
            activeTab: PAGE_TABS[0],
            needUpdateTable: false,
            
            competitors: [],
            sellers: [],
            isSelectDirty: false,
        }
    },

    computed: {
        ...mapState({
          selectedCompetitorsIds: ({ brandAndSku }) => brandAndSku.selectedCompetitorsIds,
          selectedSellersIds: ({ brandAndSku }) => brandAndSku.selectedSellersIds,
        }),
        selectedCompetitors() {
            return this.competitors.filter((competitor) => competitor.checked);
        },

        selectedSellers() {
            return this.sellers.filter((seller) => seller.checked);
        },

        tableRequestParams() {
            const params = {
                dateFrom: this.requestParams.dateFrom,
                dateTo: this.requestParams.dateTo,
                ownBrands: this.requestParams.brands.split(','),
                stores: this.requestParams.stores.split(','),
                categories: this.requestParams.categories.split(','),
                regions: this.requestParams.regions.split(','),
                competitorsBrands: this.selectedCompetitors.map((item) => item.id),
                subBrands: this.requestParams.subBrands.split(','),
            };

            if (this.selectedSellers.length) {
                params.sellers = this.selectedSellers.map((item) => item.id);
            }

            return params;
        },
    },

    methods: {
        ...mapMutations('brandAndSku', [
          'setActiveTab',
          'setSelectedCompetitorsIds',
          'setSelectedSellersIds'
        ]),
        async fetchCompetitors() {
            try {
                const response = await this.$api.common.getCompetitorsBrands(this.requestParams);
                const isResponseValid = response && Array.isArray(response.brands);

                this.competitors = isResponseValid ? this.prepareSelectItems(response.brands, 'selectedCompetitorsIds') : [];
            } catch {
                this.competitors = [];
            }
        },

        async fetchSellers() {
            try {
                const response = await this.$api.common.getSellers(this.requestParams);
                const isResponseValid = response && Array.isArray(response.sellers);

                this.sellers = isResponseValid ? this.prepareSelectItems(response.sellers, 'selectedSellersIds') : [];
            } catch {
                this.sellers = [];
            }
        },

        async fetchFilters() {
            this.isLoading = true;
            await Promise.all([this.fetchCompetitors(), this.fetchSellers()]);
            this.isLoading = false;
        },

        changeInnerFilters() {
            if (!this.isSelectDirty) return;
            this.needUpdateTable = !this.needUpdateTable;
            this.isSelectDirty = false;
        },

        prepareSelectItems(items, storeItems) {
            if (!items && !Array.isArray(items)) return [];
            return items.map((item) => ({ ...item, checked: this[storeItems].includes(item.id) }));
        },

        changeTab(tab) {
            this.setActiveTab(tab);
            this.activeTab = tab;
        },

        selectCompetitor(itemId) {
            const currentItem = this.competitors.find((item) => item.id === itemId);
            if (!currentItem) return;

            if (currentItem.checked) {
              this.setSelectedCompetitorsIds(this.selectedCompetitorsIds.filter((id) => {
                 return currentItem.id !== id
              }))
            } else {
              this.setSelectedCompetitorsIds([...this.selectedCompetitorsIds, currentItem.id])
            }

            currentItem.checked = !currentItem.checked;
            this.isSelectDirty = true;
        },

        selectAllCompetitors(isChecked) {
            this.competitors.forEach((seller) => {
                seller.checked = isChecked;
            });

            if (isChecked) {
              this.setSelectedCompetitorsIds(this.competitors.map((seller) => seller.id))
            } else {
              this.setSelectedCompetitorsIds([]);
            }

            this.isSelectDirty = true;
        },

        selectSeller(itemId) {
            const currentItem = this.sellers.find((item) => item.id === itemId);
            if (!currentItem) return;

            if (currentItem.checked) {
              this.setSelectedSellersIds(this.selectedSellersIds.filter((id) => {
                 return currentItem.id !== id
              }))
            } else {
              this.setSelectedSellersIds([...this.selectedSellersIds, currentItem.id])
            }

            currentItem.checked = !currentItem.checked;
            this.isSelectDirty = true;
        },

        selectAllSellers(isChecked) {
            this.sellers.forEach((seller) => {
                seller.checked = isChecked;
            });

            if (isChecked) {
              this.setSelectedSellersIds(this.sellers.map((seller) => seller.id))
            } else {
              this.setSelectedSellersIds([]);
            }

            this.isSelectDirty = true;
        },
    },

    watch: {
    async needUpdate() {
      await this.fetchFilters();
    },
  },

    async mounted() {
        await this.fetchFilters();
    },
}
</script>
